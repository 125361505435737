import React from 'react';
import {
  Flex,
  Box,
  Text,
  Heading,
  Container,
} from 'components';
import { useIntl } from 'context/IntlContext';
import Section from '../../../components/Section';

const MarketplaceTitle = () => {
  const { t } = useIntl();

  return (
    <Section
      noDivider
      innerSX={{
        pb: '40px',
      }}
    >
      <Flex sx={{ justifyContent: 'center' }}>
        <Box
          sx={{
            textAlign: ['center'],
          }}
        >
          <Heading
            as="h1"
            sx={{
              variant: 'text.heroTitle',
              color: 'quaternary',
              mb: 7,
            }}
          >
            {t('homepage.marketplace.header.title')}
          </Heading>
          <Text
            as="p"
            variant="underHero"
            sx={{
              color: 'quaternary',
              opacity: '0.8',
            }}
          >
            {t('homepage.marketplace.header.subtitle')}
          </Text>
        </Box>
      </Flex>
    </Section>
  );
};

export default MarketplaceTitle;
