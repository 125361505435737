import React from 'react';

import { Button, Box, Flex, Text } from 'components';
import { useIntl } from 'context/IntlContext';

import Link from 'components/Link';
import Motto from '../../../../../../../components/Motto';
import Payment from '../../icons/payment.svg';
import Financing from '../../icons/financing.svg';
import Term from '../../icons/term.svg';
import InterestRate from '../../icons/interestRate.svg';

const MixedRate = () => {
  const { t } = useIntl();

  return (
    <Box as="section" sx={{ bg: 'primaryBackground' }}>
      <Box
        sx={{
          display: ['block', 'block', 'block', 'grid'],
          gridTemplateColumns: ['1fr', '1fr', '1fr', '1fr 1fr'],
          columnGap: '160px',
          textAlign: 'initial',
        }}
      >
        <Box
          sx={{
            display: 'block',
            mb: [11, 11, 11, 0],
          }}
        >
          <Motto
            title={t(
              'homepage.marketplace.mortgage.options_section.mixed_rate.title',
            )}
            description={t(
              'homepage.marketplace.mortgage.options_section.mixed_rate.subtitle',
            )}
          />
          <Box sx={{ mt: 8, pb: 9 }}>
            <Button
              as={Link}
              to={t('homepage.marketplace.mortgage.hero.cta.link')}
              variant="primary"
              sx={{ flexShrink: 0, width: ['100%', '100%', 'auto'] }}
            >
              {t(
                'homepage.marketplace.mortgage.options_section.fixed_rate.cta',
              )}
            </Button>
            <Text
              sx={{
                color: '#233143',
                display: 'block',
                fontSize: '12px',
                lineHeight: '24px',
                marginTop: '16px',
                textAlign: 'left',
              }}>
              {t('marketplace.provider.cta.warning')}
            </Text> 
          </Box>

          <Box
            sx={{
              borderRadius: 2,
              px: [4, 4, 8, 10],
              py: [2, 2, 4, 5],
              bg: 'quaternaryBackground',
            }}
          >
            <Motto
              title={t(
                'homepage.marketplace.mortgage.options_section.mixed_rate.option1.title',
              )}
              description={t(
                'homepage.marketplace.mortgage.options_section.mixed_rate.option1.subtitle',
              )}
            />
            <Motto
              title={t(
                'homepage.marketplace.mortgage.options_section.fixed_rate.option2.title',
              )}
              description={t(
                'homepage.marketplace.mortgage.options_section.fixed_rate.option2.subtitle',
              )}
            />
          </Box>
        </Box>
        <Flex
          sx={{
            flexDirection: 'column',
            alignSelf: 'flex-start',
          }}
        >
          <Motto
            icon={<Payment />}
            title={t(
              'homepage.marketplace.mortgage.options_section.fixed_rate.payment.title',
            )}
            description={t(
              'homepage.marketplace.mortgage.options_section.mixed_rate.payment.subtitle',
            )}
          />
          <Motto
            icon={<Financing />}
            title={t(
              'homepage.marketplace.mortgage.options_section.fixed_rate.financing.title',
            )}
            description={t(
              'homepage.marketplace.mortgage.options_section.mixed_rate.financing.subtitle',
            )}
          />
          <Motto
            icon={<Term />}
            title={t(
              'homepage.marketplace.mortgage.options_section.fixed_rate.term.title',
            )}
            description={t(
              'homepage.marketplace.mortgage.options_section.mixed_rate.term.subtitle',
            )}
          />
          <Motto
            icon={<InterestRate />}
            title={t(
              'homepage.marketplace.mortgage.options_section.fixed_rate.interest_rate.title',
            )}
            description={t(
              'homepage.marketplace.mortgage.options_section.mixed_rate.interest_rate.subtitle',
            )}
          />
        </Flex>
      </Box>
    </Box>
  );
};

export default MixedRate;
