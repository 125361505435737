import React from 'react';

import {
  Image,
  Button,
  Text,
  Box,
  Heading,
  Grid,
  Flex,
} from 'components';
import { useIntl } from 'context/IntlContext';

import Link from 'components/Link';
import Section from '../../../../../components/Section';
import GiftBox from '../../../../../components/GiftBox';
import Covid from './icons/covid.png';
import impact from './icons/impact.png';
import backgroundImage from '../../../../../images/backgrounds/bgpensions.png';

import MaskIcon from '../../../../../images/icons/mask.svg';
import LineChartIcon from '../../../../../images/icons/lineChart.svg';
import Seo from '../../../../../components/SEO'
import FAQ from '../../../../../components/FAQHome';

const Donations = () => {
  const { t } = useIntl();

  return (
    <>
      <Seo
        title={t('global.meta.title')}
        description={''}
      />
      <Box as="section" sx={{ bg: 'primaryBackground', pb: [11, 11, 11, 0] }}>
        <Section
          sx={{ px: [0, 0, 0, 0] }}
        >
          <Grid
            columns={['1fr', '1fr', '1fr', '1fr 1fr']}
            sx={{
              columnGap: '0px',
              rowGap: [12, 12, 12, 0],
              textAlign: 'initial',
            }}
          >
            <Box
              sx={{
                display: 'block',
                gridColumn: ['1', '1', '1', '2'],
                gridRow: ['2', '2', '2', '1/4'],
                pr: '20px'
              }}
            >
              <Image
                src={Covid}
                alt="Covid"
                sx={{
                  display: 'block',
                  
                  height: '343px',
                  margin: '0 0 0 77px',
                  objectFit: 'contain',
                  maxWidth: ['100%', '100%', '100%', '100%', '100%', '533px'],
                }}
              />
            </Box>
            <Box
              sx={{
                gridColumn: '1',
                gridRow: '1',
              }}
            >
              <Button
                variant="primary"
                sx={{
                  flexShrink: 0,
                  width: ['100%', '100%', 'auto'],
                  bg: 'quaternaryBackground',
                  color: 'primary',
                }}
              >
                {t('homepage.marketplace.donations.tab.section_titlename')}
              </Button>
              <Text variant="sectionTitle">
                {t('homepage.marketplace.donations.tab.section_title')}
              </Text>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Text
                  variant="contentTitle"
                  sx={{
                    fontWeight: 'normal',
                  }}
                >
                  {t('homepage.marketplace.donations.tab.section_summary')}
                </Text>
              </Box>
              <Box sx={{ mt: 8 }}>
                <Button
                  as={Link}
                  to={t(
                    'homepage.marketplace.donations.cta_section.cta_button.link',
                  )}
                  variant="primary"
                  sx={{ flexShrink: 0, width: ['100%', '100%', 'auto'] }}
                >
                  {t('homepage.marketplace.donations.tab.section_button')}
                </Button>
              </Box>
              <Text
                sx={{
                  color: '#233143',
                  fontSize: '12px',
                  lineHeight: '24px',
                  marginTop: '16px',
                  textAlign: 'left',
                  display: 'block'
                }}>
                {t('marketplace.provider.cta.warning')}
              </Text> 
            </Box>
          </Grid>
        </Section>

        <Section>
          <Text as="h2" variant="sectionTitle" sx={{ textAlign: 'center' }}>
            {t('homepage.marketplace.donations.corona_section.title')}
          </Text>
          <Text variant="underHero" sx={{ pt: 7, textAlign: 'center' }}>
            {t('homepage.marketplace.donations.corona_section.subtitle')}
          </Text>
        </Section>
        <Section>
          <Text
            as="h2"
            variant="sectionTitle"
            sx={{
              textAlign: 'center',
            }}
          >
            {t('homepage.marketplace.donations.progress_section.title')}
          </Text>
          <Flex
            sx={{
              flexWrap: 'wrap',
              justifyContent: 'space-evenly',
              py: 8,
              textAlign: 'center',
            }}
          >
            <Flex
              sx={{
                flexDirection: 'column',
                alignItems: 'center',
                flex: '1 1 200px',
                py: 4,
              }}
            >
              <LineChartIcon />
              <Text variant="contentTitle">
                {t('homepage.marketplace.donations.progress_section.item1.title')}
              </Text>
              <Text variant="underContent">
                {t(
                  'homepage.marketplace.donations.progress_section.item1.subtitle',
                )}
              </Text>
            </Flex>
            <Flex
              sx={{
                flexDirection: 'column',
                alignItems: 'center',
                flex: '1 1 200px',
                py: 4,
              }}
            >
              <MaskIcon />
              <Text variant="contentTitle">
                {t('homepage.marketplace.donations.progress_section.item2.title')}
              </Text>
              <Text variant="underContent">
                {t(
                  'homepage.marketplace.donations.progress_section.item2.subtitle',
                )}
              </Text>
            </Flex>
            <Flex
              sx={{
                flexDirection: 'column',
                alignItems: 'center',
                flex: '1 1 200px',
                py: 4,
              }}
            >
              <LineChartIcon />
              <Text variant="contentTitle">
                {t('homepage.marketplace.donations.progress_section.item3.title')}
              </Text>
              <Text variant="underContent">
                {t(
                  'homepage.marketplace.donations.progress_section.item3.subtitle',
                )}
              </Text>
            </Flex>
          </Flex>
        </Section>
        <Box sx={{ pt: 14 }}>
          <Grid
            columns={['1fr', '1fr', '1fr', '1fr 1fr']}
            sx={{
              columnGap: ['0px', '0px', '0px', '160px'],
              textAlign: 'initial',
            }}
          >
            <Box
              sx={{
                display: 'block',
                gridColumn: ['2', '2', '2', '1'],
                mb: [11, 11, 11, 0],
                gridRow: ['2', '2', '2', '1/4'],
              }}
            >
              <Image
                src={impact}
                alt="Impact"
                sx={{
                  display: 'block',
                  width: 'inherit',
                  height: 'auto',
                  margin: '0 auto',
                  maxWidth: ['100%', '100%', '100%', '100%', '100%', 'inherit'],
                }}
              />
            </Box>
            <Box
              sx={{
                gridColumn: '2',
                gridRow: '1',
                mb: [9, 9, 9, 12],
              }}
            >
              <Text variant="sectionTitle">
                {t('homepage.marketplace.donations.impact_section.title')}
              </Text>

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Text
                  variant="contentTitle"
                  sx={{
                    fontWeight: 'normal',
                  }}
                >
                  {t('homepage.marketplace.donations.impact_section.subtitle')}
                </Text>
                <Text variant="contentTitle">
                  {t(
                    'homepage.marketplace.donations.impact_section.secondary_subtitle',
                  )}
                </Text>
                <Box
                  as="ul"
                  sx={{
                    p: 0,
                    pl: 7,
                    m: 0,
                    '& li::marker': {
                      color: 'primary',
                    },
                  }}
                >
                  <Text as="li" variant="content">
                    {t('homepage.marketplace.donations.impact_section.bulllet.2')}
                  </Text>
                  <Text as="li" variant="content">
                    {t('homepage.marketplace.donations.impact_section.bulllet.3')}
                  </Text>
                  <Text as="li" variant="content">
                    {t('homepage.marketplace.donations.impact_section.bulllet.4')}
                  </Text>
                  <Text as="li" variant="content">
                    {t('homepage.marketplace.donations.impact_section.bulllet.4')}
                  </Text>
                  <Text as="li" variant="content">
                    {t('homepage.marketplace.donations.impact_section.bulllet.1')}
                  </Text>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Box>
        <Section noDivider>
          <GiftBox background={backgroundImage}>
            <Heading
              as="h1"
              sx={{
                variant: 'text.sectionTitle',
                color: 'quaternary',
              }}
            >
              {t('homepage.marketplace.donations.cta_section.title')}
            </Heading>
            <Text
              sx={{
                py: 10,
                display: 'block'
              }}
            >
              {t('homepage.marketplace.donations.cta_section.subtitle')}
            </Text>

            <Button
              as={Link}
              to={t('homepage.marketplace.donations.cta_section.cta_button.link')}
              variant="primary"
              sx={{
                flexShrink: 0,
                width: ['100%', '100%', 'auto'],
                m: '0 auto',
              }}
            >
              {t('homepage.marketplace.donations.cta_section.cta_button')}
            </Button>
            <Text
              sx={{
                color: '#233143',
                fontSize: '12px',
                lineHeight: '24px',
                marginTop: '40px',
                display: 'block'
              }}>
              {t('marketplace.provider.cta.warning')}
            </Text> 
          </GiftBox>
        </Section>
      </Box>
    </>
  );
};

export default Donations;
