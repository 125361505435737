import React, { useEffect, useState } from 'react';
import { submitEmail } from 'api';
import { alpha } from '@theme-ui/color';

import { EMAIL_REGEX } from 'utils';
import { useIntl } from 'context/IntlContext';
import { useLinks } from 'context/LinksContext';

import {
  Box,
  Button,
  Flex,
  Input,
  Text,
} from 'components';

import Link from 'components/Link';

interface DirectSignupProps {
  isBusiness?: boolean;
  variant?: 'dark' | 'light';
  color?: string;
}

const DirectSignup: React.FC<DirectSignupProps> = ({
  isBusiness,
  variant = 'light',
  color,
  ...props
}) => {
  const { t, locale } = useIntl();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const { login, signup } = useLinks();
  const profileType = isBusiness ? 'BUSINESS' : 'PERSONAL';

  useEffect(() => {
    if (error && (!email || EMAIL_REGEX.test(email))) {
      setError('');
    }
  }, [email, error]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!email) {
      setError('enterAValidEmail');
      return;
    }
    if (!EMAIL_REGEX.test(email)) {
      setError('global.errors.message.invalid_email');
      return;
    }

    setLoading(true);
    const errorCode = await submitEmail({
      email,
      profileType,
      locale,
      signup: signup.link,
      login,
    });
    setLoading(false);
    setError(errorCode);
  };

  return (
    <Box {...props}>
      <form onSubmit={handleSubmit}>
        <Flex
          sx={{
            alignItems: 'baseline',
            flexDirection: ['column', 'column', 'row'],
            mb: 9,
            justifyContent: 'stretch',
          }}
        >
          <Input
            value={email}
            placeholder={t('homepage.home.hero.email_placeholder')}
            onChange={(e) => setEmail(e.target.value.trim().toLowerCase())}
            disabled={loading}
            sx={{
              flexGrow: 1,
              backgroundColor:
                variant === 'dark' ? 'tertiary' : alpha('tertiary', 0.1),
              border:
                variant === 'dark' ? 'solid 1px rgba(35, 49, 67, 0.1)' : 'none',
              color: variant === 'dark' ? 'quaternary' : 'tertiary',
              mb: [2, 2, 0],
              '::placeholder': {
                color:
                  variant === 'dark'
                    ? alpha('quaternary', 0.5)
                    : alpha('tertiary', 0.5),
                fontSize: '14px',
              },
            }}
          />
          <Button
            variant="primary"
            type="submit"
            sx={{ flexShrink: 0, width: ['100%', '100%', 'auto'] }}
          >
            {t('global.buttons.signup')}
          </Button>
        </Flex>
      </form>
      <Text
        sx={{
          display: 'block',
          color: '#233143',
          fontSize: '12px',
          lineHeight: '24px',
          margin: '16px 0 44px 0',
        }}>
        {t('marketplace.provider.cta.warning')}
      </Text>
    </Box>
  );
};

export default DirectSignup;
