import React, { useEffect } from 'react';
import { useColorMode } from 'theme-ui';

import { useIntl } from 'context/IntlContext';
import MarketplaceTitle from './MarketplaceTitle';
import TopSection from './TopSection';
import Seo from '../../components/SEO';

const PAGE_COLOR_MODE = 'light';

const MarketplacePage = () => {
  const { t } = useIntl();
  const [colorMode, setColorMode] = useColorMode();
  useEffect(() => {
    if (colorMode !== PAGE_COLOR_MODE) {
      setColorMode(PAGE_COLOR_MODE);
    }
  }, [colorMode, setColorMode]);

  return (
    <>
      <Seo title={t('global.meta.title')} description={''} />
      <MarketplaceTitle />
      <TopSection />
    </>
  );
};

export default MarketplacePage;
