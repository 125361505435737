import React, { ReactElement, useState } from 'react';
import { Flex, Box, Select } from 'components';

import TabTitle from '../MegaTabs/TabTitle';

type Props = {
  children: ReactElement[];
  activeTab?: string | number;
};

const Tabs: React.FC<Props> = ({ children, activeTab }) => {
  const [selectedTab, setSelectedTab] = useState(activeTab || 0);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedTab(e.target.value);
  };

  return (
    <Box>
      <Flex
        sx={{
          py: 8,
          justifyContent: 'center',
          alignItems: 'center',
          '& > div': {
            width: '100%',
            height: '40px',
            display: ['flex', 'flex', 'none', 'none'],
          },
        }}
      >
        <Select
          variant="fullWidth"
          onChange={handleChange}
          sx={{
            '&, & + svg': {
              display: ['block', 'block', 'none', 'none'],
              color: 'primary',
            },
            width: '100%',
            borderRadius: 2,
            py: 3,
            borderColor: 'senary',
          }}
        >
          {children.map((item, index) => (
            <option key={item.props.title} value={item.props.hash || index}>
              {item.props.title}
            </option>
          ))}
        </Select>
        {children.map((item, index) => (
          <TabTitle
            key={item.props.title}
            title={item.props.title}
            isActive={selectedTab === item.props.hash || selectedTab === index}
            index={index}
            setSelectedTab={setSelectedTab}
          />
        ))}
      </Flex>
      {children[selectedTab] ||
        children.find(({ props }) => props.hash === selectedTab)}
    </Box>
  );
};

export default Tabs;
