import React from 'react';
import {
  Text,
  Box,
  Image,
  Grid,
  Container,
} from 'components';
import { useIntl } from 'context/IntlContext';

import Asset from './icons/asset.svg';
import BuyBack from './icons/buyback.svg';
import Safe from './icons/safe.svg';
import funds from './icons/funds.jpg';
import Section from '../../../../../components/Section';

const FundsProtectionSection = () => {
  const { t } = useIntl();

  return (
    <Box as="section" sx={{ bg: 'primaryBackground' }}>
      <Grid
        columns={['1fr', '1fr', '1fr', '1fr 1fr']}
        sx={{
          columnGap: '160px',
          textAlign: 'initial',
        }}
      >
        <Box
          sx={{
            display: 'block',
            gridColumn: ['1', '1', '1', '2'],
            mb: [11, 11, 11, 0],
            gridRow: ['2', '2', '2', '1/4'],
          }}
        >
          <Image
            src={funds}
            alt="Funds"
            sx={{
              display: 'block',
              width: 'inherit',
              height: 'auto',
              margin: '0 auto',
              maxWidth: '100%',
            }}
          />
        </Box>
        <Box
          sx={{
            gridColumn: '1',
            gridRow: '1',
            mb: [9, 9, 9, 12],
          }}
        >
          <Text mb={5} variant="sectionTitle">
            {t('homepage.marketplace.protection_section.title') ||
              'How your funds are protected.'}
          </Text>
        </Box>
        <Box
          sx={{
            gridColumn: '1',
            gridRow: ['3', '3', '3', '2'],
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Asset />
            <Text
              variant="contentTitle"
              sx={{
                fontWeight: 'semibold',
                pl: 20,
              }}
            >
              {t('homepage.marketplace.protection_section.asset.title') ||
                'Asset-backed loans'}
            </Text>
          </Box>

          <Text
            variant="underContent"
            sx={{
              fontWeight: 'normal',
              maxWidth: 452,
              mt: [5],
            }}
          >
            {t('homepage.marketplace.protection_section.asset.subtitle') ||
              'All marketplace investments are backed by collateral (asset/property).'}
          </Text>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              pt: 9,
            }}
          >
            <BuyBack />

            <Text
              variant="contentTitle"
              sx={{
                fontWeight: 'semibold',
                pl: 20,
              }}
            >
              {t('homepage.marketplace.protection_section.buyback.title') ||
                'Buyback guarantee'}
            </Text>
          </Box>
          <Text
            variant="underContent"
            sx={{
              fontWeight: 'normal',
              maxWidth: 452,
              mt: [5],
            }}
          >
            {t('homepage.marketplace.protection_section.buyback.subtitle') ||
              'Loan Originators of our marketplace investments commit to providing a Buyback Guarantee where, if a loan repayment enters a delay exceeding 90 days, a re-purchase is executed.'}
          </Text>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              pt: 9,
            }}
          >
            <Safe />
            <Text
              variant="contentTitle"
              sx={{
                fontWeight: 'semibold',
                pl: 20,
              }}
            >
              {t('homepage.marketplace.protection_section.safeguard.title') ||
                ' Safeguard trust'}
            </Text>
          </Box>
          <Text
            variant="underContent"
            sx={{
              fontWeight: 'normal',
              maxWidth: 452,
              mt: [5],
            }}
          >
            {t('homepage.marketplace.protection_section.safeguard.subtitle') ||
              'This constitutes a provision of funds set aside on a monthly basis which can be used to cover any losses that the above described protections were not able to fully mitigate.'}
          </Text>
        </Box>
      </Grid>
    </Box>
  );
};

export default FundsProtectionSection;
