import React, { FC } from 'react';
import { Box } from 'components';

interface GiftBoxProps {
  background: string;
}

const GiftBox: FC<GiftBoxProps> = ({ background = '', children }) => (
  <Box
    sx={{
      py: 10,
    }}
  >
    <Box
      sx={{
        borderRadius: 3,
        backgroundColor: 'quaternaryBackground',
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        textAlign: 'center',
        p: 9,
      }}
    >
      {children}
    </Box>
  </Box>
);

export default GiftBox;
