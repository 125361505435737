import React from 'react';

import { useIntl } from 'context/IntlContext';

import {
  Button,
  Text,
  Box,
  Image,
  Grid,
  Container,
} from 'components';

import Link from 'components/Link';
import MortgageOptionSection from './MortgageOptionsSection';
import Motto from '../../../../../components/Motto';
import Section from '../../../../../components/Section';

import mortgages from './icons/mortgages.png';

import Analisys from './icons/analisys.svg';
import Time from './icons/time.svg';
import Support from './icons/support.svg';
import Bank from './icons/bank.svg';
import Seo from '../../../../../components/SEO';
import FAQ from '../../../../../components/FAQHome';

const Mortgages = () => {
  const { t } = useIntl();

  return (
    <>
      <Seo title={t('global.meta.title')} description={''} />
      <Box sx={{ bg: 'primaryBackground', pb: [11, 11, 11, 0] }}>
        <Container sx={{ px: [0, 0, 0, 0] }}>
          <Section sx={{ px: [0, 0, 0, 0] }}>
            <Grid
              columns={['1fr', '1fr', '1fr', '1fr 1fr']}
              sx={{
                columnGap: '0px',
                textAlign: 'initial',
              }}
            >
              <Box
                sx={{
                  display: 'block',
                  gridColumn: ['1', '1', '1', '2'],
                  mb: [11, 11, 11, 0],
                  gridRow: ['2', '2', '2', '1/4'],
                  pr: '20px',
                }}
              >
                <Image
                  src={mortgages}
                  alt="Mortgages"
                  sx={{
                    display: 'block',
                    width: '533px',
                    height: '393px',
                    margin: '0 0 0 80px',
                    objectFit: 'contain',
                  }}
                />
              </Box>
              <Box
                sx={{
                  gridColumn: '1',
                  gridRow: '1',
                }}
              >
                <Text mb={5} variant="sectionTitle">
                  {t('homepage.marketplace.mortgage.hero.title')}
                </Text>

                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Text
                    variant="contentTitle"
                    sx={{
                      fontWeight: 'normal',
                      opacity: '0.8',
                    }}
                  >
                    {t('homepage.marketplace.mortgage.hero.subtitle')}
                  </Text>
                </Box>
              </Box>
              <Box>
                <Button
                  as={Link}
                  to={t('homepage.marketplace.mortgage.cta.link')}
                  variant="primary"
                  sx={{ flexShrink: 0, width: ['100%', '100%', 'auto'] }}
                >
                  {t('homepage.marketplace.mortgage.options_section.fixed_rate.cta')}
                </Button>
                <Text
                  sx={{
                    color: '#233143',
                    display: 'block',
                    fontSize: '12px',
                    lineHeight: '24px',
                    marginTop: '16px',
                    textAlign: 'left',
                  }}>
                  {t('marketplace.provider.cta.warning')}
                </Text> 
              </Box>
            </Grid>
          </Section>
          <Section>
            <MortgageOptionSection />
          </Section>
          <Section noDivider>
            <Box>
              <Text as="h2" variant="sectionTitle">
                {t('homepage.marketplace.mortgage.benefit_section.title')}
              </Text>
              <Text variant="underHero" sx={{ pt: 7, pb: 14 }}>
                {t('homepage.marketplace.mortgage.benefit_section.subtitle')}
              </Text>

              <Grid
                columns={['1fr', '1fr', '1fr', '1fr 1fr']}
                sx={{
                  columnGap: '160px',
                  textAlign: 'initial',
                  py: 8,
                }}
              >
                <Motto
                  title={t(
                    'homepage.marketplace.mortgage.benefit_section.pre_analysis.title',
                  )}
                  description={t(
                    'homepage.marketplace.mortgage.benefit_section.pre_analysis.subtitle',
                  )}
                  icon={<Analisys />}
                />
                <Motto
                  title={t(
                    'homepage.marketplace.mortgage.benefit_section.time.title',
                  )}
                  description={t(
                    'homepage.marketplace.mortgage.benefit_section.time.subtitle',
                  )}
                  icon={<Time />}
                />
                <Motto
                  title={t(
                    'homepage.marketplace.mortgage.benefit_section.support.title',
                  )}
                  description={t(
                    'homepage.marketplace.mortgage.benefit_section.support.subtitle',
                  )}
                  icon={<Support />}
                />
                <Motto
                  title={t(
                    'homepage.marketplace.mortgage.benefit_section.bank.title',
                  )}
                  description={t(
                    'homepage.marketplace.mortgage.benefit_section.bank.subtitle',
                  )}
                  icon={<Bank />}
                />
              </Grid>
              <Box
                sx={{
                  borderRadius: 2,
                  px: [4, 4, 8, 10],
                  pt: 3,
                  pb: 8,
                  bg: 'quaternaryBackground',
                }}
              >
                <Text variant="contentTitle">
                  {t('homepage.marketplace.mortgage.trust_section.title')}
                </Text>
                <Text>
                  {t('homepage.marketplace.mortgage.trust_section.subtitle')}
                </Text>
              </Box>
            </Box>
          </Section>
        </Container>
      </Box>
    </>
  );
};

export default Mortgages;
