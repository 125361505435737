import React from 'react';

import { useIntl } from 'context/IntlContext';
import { useLocation } from 'context/LocationContext';

import { Button, Text, Box, Image, Grid, Container, Heading } from 'components';

import Link from 'components/Link';
import Section from '../../../../../components/Section';
import GiftBox from '../../../../../components/GiftBox';
import backgroundImage from '../../../../../images/backgrounds/bgpensions.png';

import business_loans from './icons/business_loans.png';
import change_capital from './icons/change_capital_logo.png';
import TableEn from '../../../../../images/cashConversionTables/table_en.svg';
import TableIt from '../../../../../images/cashConversionTables/table_it.svg';

import Seo from '../../../../../components/SEO';
import Video from './Video';

import { graphql, useStaticQuery } from 'gatsby';

const CashConversionCycle = () => {
  const { t, locale } = useIntl();
  const { location } = useLocation();

  const query = graphql`
    query FilesQuery {
      allFile(filter: { relativePath: { regex: "/cashConversionTables/" } }) {
        edges {
          node {
            name
            publicURL
          }
        }
      }
    }
  `;
  const { allFile } = useStaticQuery(query);
  const getImageURL = () => {
    if (locale === 'it') {
      return allFile.edges.find(({ node }: any) => node.name === 'table_it')
        .node.publicURL;
    } else {
      return allFile.edges.find(({ node }: any) => node.name === 'table_en')
        .node.publicURL;
    }
  };

  return (
    <>
      <Seo title={t('global.meta.title')} description={''} />
      <Box sx={{ bg: 'primaryBackground', pb: [11, 11, 11, 0] }}>
        <Container sx={{ px: [0, 0, 0, 0] }}>
          <Section>
            <Grid
              columns={['1fr', '1fr', '1fr', '1fr 1fr']}
              sx={{
                columnGap: '0px',
                textAlign: 'initial',
              }}
            >
              <Box
                sx={{
                  display: 'block',
                  gridColumn: ['1', '1', '1', '2'],
                  mb: [11, 11, 11, 0],
                  gridRow: ['2', '2', '2', '1/4'],
                  pr: '20px',
                }}
              >
                <Image
                  src={business_loans}
                  alt="Business Loans"
                  sx={{
                    display: 'block',
                    width: '533px',
                    height: '360px',
                    margin: '0 0 0 0px',
                    objectFit: 'contain',
                  }}
                />
              </Box>
              <Box
                sx={{
                  gridColumn: '1',
                  gridRow: '1',
                  pr: [0, 0, '81px'],
                }}
              >
                <Image
                  src={change_capital}
                  alt="Change Capital"
                  sx={{
                    display: 'block',
                    width: '150px',
                    height: '33px',
                    margin: '0 302px 31px 0',
                    objectFit: 'contain',
                  }}
                />
                <Text mb={5} variant="sectionTitle">
                  {t('marketplace.cash_conversion_cycle.atf.title')}
                </Text>

                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Text
                    variant="contentTitle"
                    sx={{
                      fontWeight: 'normal',
                      opacity: '0.8',
                    }}
                  >
                    {t('marketplace.cash_conversion_cycle.atf.subtitle')}
                  </Text>
                </Box>
              </Box>
              <Box>
                <Button
                  as={Link}
                  to={t('marketplace.cash_conversion_cycle.atf.cta_link')}
                  variant="primary"
                  sx={{ flexShrink: 0, width: ['100%', '100%', 'auto'] }}
                >
                  {t('marketplace.cash_conversion_cycle.atf.cta_value')}
                </Button>
                <Text
                  sx={{
                    color: '#233143',
                    display: 'block',
                    fontSize: '12px',
                    lineHeight: '24px',
                    marginTop: '16px',
                    textAlign: 'left',
                  }}
                >
                  {t('marketplace.provider.cta.warning')}
                </Text>
              </Box>
            </Grid>
          </Section>
          <Section>
            <Box sx={{ textAlign: ['start', 'start', 'center', 'center'] }}>
              <Text as="h2" variant="sectionTitle">
                {t('marketplace.cash_conversion_cycle.table_section.title')}
              </Text>
              <Text variant="underHero" sx={{ pt: 7 }}>
                {t('marketplace.cash_conversion_cycle.table_section.subtitle')}
              </Text>
              <Box
                sx={{
                  display: ['none', 'none', 'block', 'block'],
                  mt: '80px',
                }}
              >
                <Text
                  variant="underContent"
                  sx={{
                    mb: '16px',
                    textAlign: 'center',
                  }}
                >
                  {t(
                    'marketplace.cash_conversion_cycle.table_section.table_title',
                  )}
                </Text>
                {locale === 'it' ? (
                  <TableIt alt="Cash Conversion Cycle" />
                ) : (
                  <TableEn alt="Cash Conversion Cycle" />
                )}
              </Box>
              <Text
                variant="contentTitle"
                sx={{
                  textAlign: ['start', 'start', 'center', 'center'],
                  mt: ['40px', '40px', '80px', '80px'],
                }}
              >
                {t('marketplace.cash_conversion_cycle.table_section.cta_title')}
              </Text>
              <Text
                variant="underContent"
                sx={{
                  pb: '40px',
                  textAlign: ['start', 'start', 'center', 'center'],
                }}
              >
                {t(
                  'marketplace.cash_conversion_cycle.table_section.cta_subtitle',
                )}
              </Text>
            </Box>
          </Section>
          <Section>
            <Grid
              columns={['1fr', '1fr', '1fr', '1fr 1fr']}
              sx={{
                columnGap: '0px',
                textAlign: 'initial',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  alignItems: 'center',
                  gridColumn: ['1', '1', '1', '2'],
                  mb: [11, 11, 11, 0],
                  gridRow: ['2', '2', '2', '1/4'],
                  pl: [0, 0, 0, '48px'],
                }}
              >
                <Video width="452px" height="301px" title="Video" />
              </Box>
              <Box
                sx={{
                  gridColumn: '1',
                  gridRow: '1',
                }}
              >
                <Text mb={5} variant="sectionTitle">
                  {t('marketplace.cash_conversion_cycle.video_section.title')}
                </Text>

                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Text
                    variant="contentTitle"
                    sx={{
                      fontWeight: 'normal',
                      opacity: '0.8',
                    }}
                  >
                    {t(
                      'marketplace.cash_conversion_cycle.video_section.subtitle',
                    )}
                  </Text>
                </Box>
              </Box>
            </Grid>
          </Section>
          <Section noDivider>
            <GiftBox background={backgroundImage}>
              <Heading
                as="h1"
                sx={{
                  variant: 'text.sectionTitle',
                  color: 'quaternary',
                }}
              >
                {t('marketplace.cash_conversion_cycle.cta_section.title')}
              </Heading>
              <Text
                sx={{
                  display: 'block',
                  py: 10,
                }}
              >
                {t('marketplace.cash_conversion_cycle.cta_section.description')}
              </Text>

              <Button
                as={Link}
                to={t('homepage.marketplace.investment_funds.cta.link')}
                variant="primary"
                sx={{
                  flexShrink: 0,
                  width: ['100%', '100%', 'auto'],
                  m: '0 auto',
                }}
              >
                {t('open_account_button.value')}
              </Button>
              <Text
                sx={{
                  color: '#233143',
                  display: 'block',
                  fontSize: '12px',
                  lineHeight: '24px',
                  marginTop: '40px',
                }}
              >
                {t('marketplace.provider.cta.warning')}
              </Text>
            </GiftBox>
          </Section>
        </Container>
      </Box>
    </>
  );
};

export default CashConversionCycle;
