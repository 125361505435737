import React from 'react';
import { Box } from 'components';

interface VideoProps {
  width: string;
  height: string;
  title: string;
}

const Video = ({ width, height, title }: VideoProps) => {
  return (
    <>
      <Box
        sx={{
          borderRadius: '20px',
          overflow: 'hidden',
          width,
          height,
          display: ['block', 'block', 'none', 'none'],
          filter: 'drop-shadow(0px 10px 20px rgba(35, 49, 66, 0.1))',
        }}
      >
        <iframe
          sx={{
            width: [''],
          }}
          height={height}
          src="https://www.youtube.com/embed/FPIO2jV8ZRQ"
          title={title}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        ></iframe>
      </Box>

      <Box
        sx={{
          borderRadius: '20px',
          overflow: 'hidden',
          width,
          height,
          display: ['none', 'none', 'block', 'block'],
          filter: 'drop-shadow(0px 10px 20px rgba(35, 49, 66, 0.1))',
        }}
      >
        <iframe
          width={width}
          height={height}
          src="https://www.youtube.com/embed/FPIO2jV8ZRQ"
          title={title}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        ></iframe>
      </Box>
    </>
  );
};

export default Video;
