import React from 'react';

import { Text, Box, Container } from 'components';
import { useIntl } from 'context/IntlContext';

import Tabs from '../../../../../../components/Tabs';
import Tab from '../../../../../../components/Tabs/Tab';
import FixedRate from './TabContent/FixedRate';
import MixedRate from './TabContent/MixedRate';
import VariableRate from './TabContent/VariableRate';

const MortgageOptionSection = () => {
  const { t } = useIntl();

  return (
    <Box
      as="section"
      sx={{
        bg: 'primaryBackground',
        textAlign: 'center',
      }}
    >
      <Text as="h2" variant="sectionTitle">
        {t('homepage.marketplace.mortgage.options_section.title')}
      </Text>
      <Text variant="underHero" sx={{ pt: 7 }}>
        {t('homepage.marketplace.mortgage.options_section.subtitle')}
      </Text>
      <Box>
        <Tabs>
          <Tab
            title={t(
              'homepage.marketplace.mortgage.options_section.fixed_rate',
            )}
          >
            <FixedRate />
          </Tab>
          <Tab
            title={t(
              'homepage.marketplace.mortgage.options_section.mixed_rate',
            )}
          >
            <MixedRate />
          </Tab>
          <Tab
            title={t(
              'homepage.marketplace.mortgage.options_section.variable_rate',
            )}
          >
            <VariableRate />
          </Tab>
        </Tabs>
      </Box>
    </Box>
  );
};

export default MortgageOptionSection;
